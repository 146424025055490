import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import SEO from "../components/SEO/seo"
import { StyledA, SitemapContainer } from "../components/commonFeatureComp"

const ContentH1 = styled.h1`
  font-size: 42px;
  color: rgba(22, 31, 50, 1);
  @media (max-width: 575px) {
    font-size: 23px;
  }
`
const ContentH2 = styled.div`
  font-size: 26px;
  color: rgba(22, 31, 50, 1);
  @media (max-width: 1450px) {
    font-size: 24px;
  }
`

const Sitemap = ({ data, location }) => {
  console.log(data.allSitePage.nodes)

  return (
    <>
      <SEO
        title="sitemap groupboss"
        description="sitemap of groupboss"
        pathname={location.pathname}
      />
      <SitemapContainer>
      <ContentH1>Groupboss Site Map</ContentH1>
      <ContentH2>Uses</ContentH2>
      {data.allSitePage.nodes &&
        data.allSitePage.nodes
          .filter(item => !item.path.includes("404") && item.path !== "/")
          .map((filteredItem, index) => (
            <p>
              <StyledA
                target="_blank"
                rel="noopener noreferrer"
                href={`https://groupboss.io${filteredItem.path}`}
                key={index}
              >
                {filteredItem.path
                  .split("-")
                  .join(" ")
                  .substring(1, filteredItem.path.length - 1)}
              </StyledA>
            </p>
          ))}
      </SitemapContainer>
    </>
  )
}

export const query = graphql`
  query HomePageQuery {
    allSitePage {
      nodes {
        path
      }
    }
  }
`

export default Sitemap
