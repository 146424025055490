import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import abstractShape from "../../images/abstract-shape.png"

const StyledCard = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-content: space-evenly;
  padding: ${props => props.cardPadding};
  box-shadow: 0px 10px 30px rgba(194, 204, 225, 0.35);
  grid-row-gap: 15px;
  border-radius: 10px;
  transition: all 0.4s ease-out;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 10px 30px rgba(194, 204, 225, 0.5);
  }
`

const CardImage = styled.div`
  display: grid;
  grid-template-columns: 40%;
  justify-content: center;
  align-content: center;
  /* padding: 5px 5px 20px 5px; */
`

const CardTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  color: rgba(47, 57, 78, 0.7);
  font-size: 16px;
  text-align: center;
  font-weight: 700;
`
const CardText = styled.p`
  text-align: center;
  font-family: "Montserrat", sans-serif;
  color: #3d4d69;
  font-size: 16px;
  font-weight: ${props => props.fontWeight};
`
export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 20% 20% 20%;
  grid-column-gap: 30px;
  justify-content: center;
  margin: 80px 0 40px 0;
  @media (max-width: 1100px) {
    padding: 0 20px;
    grid-template-columns: 30% 30% 30%;
  }
  @media (max-width: 767px) {
    grid-template-columns: 50%;
    grid-row-gap: 15px;
  }
  @media (max-width: 550px) {
    grid-template-columns: 70%;
    grid-row-gap: 15px;
  }
`

export const CommonContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => props.gridColumns};
  align-content: center;
  justify-content: ${props => props.justifyContent};
  margin: 100px 0;
  font-family: "Montserrat", sans-serif;
  grid-column-gap: 40px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`

export const CommonColorContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => props.gridColumns};
  align-content: center;
  justify-content: ${props => props.justifyContent};
  margin: 60px 0;
  font-family: "Montserrat", sans-serif;
  grid-column-gap: 40px;
  padding: ${props => props.containerPadding};
  background-image: linear-gradient(to right, #33ccae, #008faa);
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`

export const CommonImageSection = styled.div`
  display: grid;
  grid-template-columns: 100%;
  @media (max-width: 768px) {
    grid-template-columns: 80%;
    justify-content: center;
  }
`

export const CommonImageDiv = styled.div`
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 50%;
    justify-content: center;
    align-content: ${props => props.CommonImageDivContent};
  }
  @media (max-width: 575px) {
    grid-template-columns: 100%;
  }
`

export const CommonTextContainer = styled.div`
  display: grid;
  align-content: center;
  grid-template-columns: ${props => props.textGridColumn};
  justify-content: ${props => props.textJustify};
  grid-row-gap: 10px;
  @media (max-width: 768px) {
    margin-top: 10px;
    grid-template-columns: 60%;
    text-align: center;
    justify-content: center;
    padding: 30px 0;
  }
  @media (max-width: 575px) {
    grid-template-columns: auto;
    padding: 30px 20px;
  }
`
export const CommonContentH2 = styled.div`
  font-size: 22px;
  color: rgba(22, 31, 50, 1);
  @media (max-width: 1450px) {
    font-size: 18px;
  }
`

export const CommonContentP = styled.div`
  font-size: 18px;
  color: rgba(22, 31, 50, 0.6);
  margin: 15px 0;
  @media (max-width: 1450px) {
    font-size: 15px;
    color: rgba(22, 31, 50, 0.7);
    line-height: 1.6;
  }
`
export const AndroidChromeButton = styled.div`
  width: 144px;
  margin-top: 40px;
  cursor: pointer;
  @media (max-width: 768px) {
    margin: auto;
    margin-top: 20px;
  }
`
export const CardContainerGroup = styled.div`
  display: grid;
  grid-template-columns: 30% 30%;
  justify-content: center;
  align-content: center;
  grid-gap: 40px;
  @media (max-width: 1200px) {
    grid-template-columns: 40% 40%;
  }
  @media (max-width: 960px) {
    grid-template-columns: 45% 45%;
  }
  @media (max-width: 768px) {
    grid-template-columns: 37% 37%;
  }
  @media (max-width: 575px) {
    grid-template-columns: 70%;
  }
`
export const CommonText = styled.p`
  margin: ${props => (props.margin ? props.margin : `0`)};
  color: ${props => (props.name ? `#1F2939` : `#5D687E`)};
  font-size: ${props => (props.fontSize ? props.fontSize : `18px`)};
  font-weight: ${props => props.fontWeight && props.fontWeight};
  @media (max-width: 1450px) {
    font-size: ${props => (props.fontSize ? props.fontSize : `16px`)};
  }
  @media (max-width: 768px) {
    font-size: ${props => (props.fontSize ? props.fontSize : `14px`)};
  }
`

export const CommonTitle = styled.p`
  margin: ${props => (props.margin ? props.margin : `0`)};
  color: ${props => (props.color ? props.color : `#1F2939`)};
  font-size: ${props => (props.fontSize ? props.fontSize : `34px`)};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : `600`)};
  text-transform: ${props =>
    props.textTransform ? props.textTransform : `capitalize`};
  @media (max-width: 1450px) {
    font-size: ${props => (props.fontSize ? props.fontSize : `32px`)};
  }
  @media (max-width: 768px) {
    font-size: ${props => (props.fontSize ? props.fontSize : `28px`)};
  }
`
export const CommonTitleH2 = styled.h2`
  margin: ${props => (props.margin ? props.margin : `0`)};
  color: ${props => (props.color ? props.color : `#2f394e`)};
  font-size: ${props => (props.fontSize ? props.fontSize : `32px`)};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : `700`)};
  font-family: "Montserrat", sans-serif;
  text-transform: ${props =>
    props.textTransform ? props.textTransform : `capitalize`};
  @media (max-width: 575px) {
    font-size: ${props => (props.fontSize ? props.fontSize : `23px`)};
  }
`
export const CommonTitleH3 = styled.h3`
  margin: ${props => (props.margin ? props.margin : `0`)};
  color: ${props => (props.color ? props.color : `#1F2939`)};
  font-size: ${props => (props.fontSize ? props.fontSize : `34px`)};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : `600`)};
  font-family: "Montserrat", sans-serif;
  text-transform: ${props =>
    props.textTransform ? props.textTransform : `capitalize`};
  @media (max-width: 1450px) {
    font-size: ${props => (props.fontSize ? props.fontSize : `32px`)};
  }
  @media (max-width: 768px) {
    font-size: ${props => (props.fontSize ? props.fontSize : `28px`)};
  }
`
export const CommonTitleH4 = styled.h4`
  margin: ${props => (props.margin ? props.margin : `0`)};
  color: ${props => (props.color ? props.color : `#1F2939`)};
  font-size: ${props => (props.fontSize ? props.fontSize : `34px`)};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : `600`)};
  font-family: "Montserrat", sans-serif;
  text-transform: ${props =>
    props.textTransform ? props.textTransform : `capitalize`};
  @media (max-width: 1450px) {
    font-size: ${props => (props.fontSize ? props.fontSize : `32px`)};
  }
  @media (max-width: 768px) {
    font-size: ${props => (props.fontSize ? props.fontSize : `28px`)};
  }
`
export const CommonColorButton = styled.div`
  color: ${props => (props.color ? props.color : `white`)};
  font-size: ${props => (props.size ? props.size : `14px`)};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 600)};
  border-radius: ${props => (props.radius ? props.radius : `6px`)};
  background-color: ${props => (props.bgColor ? props.bgColor : `#20BEAD`)};
  padding: ${props => (props.padding ? props.padding : `10px 25px`)};
  cursor: ${props => (props.cursor ? props.cursor : `pointer`)};
  white-space: nowrap;
  text-align: center;
  transition: ${props => props.effect === "effect" && `opacity 0.2s`};
  &:hover {
    background-color: ${props => props.hoverBg && props.hoverBg};
  }
`
export const GroupManageTitle = styled.h3`
  font-size: 32px;
  color: #33475b;
  margin-bottom: 15px;
  @media (max-width: 575px) {
    font-size: 18px;
  }
`
export const BottomLine = styled.div`
  width: 50px;
  height: 3px;
  border-radius: 20px;
  background-color: ${props => (props.bgColor ? `#6F879E` : `#33cdd1`)};
  @media (max-width: 800px) {
    margin: 0 auto;
  }
`
export const StyledA = styled.a`
  text-decoration: ${props => props.textDecoration || `none`};
  color: ${props => props.color && props.color};
`
export const StyledLink = styled(Link)`
  text-decoration: ${props => props.textDecoration || `none`};
  color: ${props => props.color && props.color};
`
export const SitemapContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 840px);
  justify-content: center;
  margin: 20px 40px 40px;
`
const TrialContainer2 = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 430px) auto;
  justify-content: space-evenly;
  align-items: center;
  gap: 25px;
  padding: 5em 40px 5em 40px;
  margin: 2em auto;
  border-radius: 10px;
  background-color: #33475b;
  background-image: url(${abstractShape});
  background-position: center right;
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 980px;
  @media (max-width: 1200px) {
    grid-template-columns: minmax(auto, 360px) auto;
    margin: 2em 30px;
  }
  @media (max-width: 820px) {
    grid-template-columns: minmax(auto, 260px);
  }
  @media (max-width: 575px) {
    grid-template-columns: minmax(auto, 290px);
    text-align: center;
    justify-content: center;
    align-content: space-evenly;
    padding: 4em 25px 25px 25px;
    row-gap: 25px;
    min-height: 300px;
  }
`
const Template2h2 = styled.h2`
  margin: 0 0 12px 0;
  color: white;
  font-size: 26px;
  @media (max-width: 575px) {
    font-size: 18px;
    line-height: 1.6;
  }
`
const TrialTemplateText = styled.p`
  font-size: 15px;
  color: white;
  line-height: 1.6;
  @media (max-width: 575px) {
    font-size: 13px;
  }
`
const JoinButton = styled.a`
  padding: 16px 30px;
  border-radius: 6px;
  background-color: #33CBCF;
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
`

export const CommonCard = props => (
  <StyledCard
    cardPadding={props.cardPadding}
    cardTransFrom={props.cardTransFrom}
  >
    {props.cardImage && <CardImage>{props.cardImage}</CardImage>}
    {props.cardTitle && <CardTitle>{props.cardTitle}</CardTitle>}
    {props.cardText && (
      <CardText fontWeight={props.cardTextWeight}>{props.cardText}</CardText>
    )}
  </StyledCard>
)

export const TrialTemplate2 = props => (
  <TrialContainer2>
    <div>
      <Template2h2>It’s Time to Start</Template2h2>
      <TrialTemplateText>
        Ready to boost your earnings with unlimited commissions through
        Groupboss?
      </TrialTemplateText>
    </div>
    <JoinButton href="https://affiliates.groupboss.io/login">Join Groupboss Affiliate Program</JoinButton>
  </TrialContainer2>
)
